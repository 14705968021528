import { Controller } from "@hotwired/stimulus"
import { useWindowResize } from 'stimulus-use'
// Connects to data-controller="dates"
export default class extends Controller {

  static targets = ["scrollBar"]
  widthScroll = this.scrollBarTarget.scrollWidth;
  clientWidthScroll = this.scrollBarTarget.clientWidth;
  maxScrollLeft = this.widthScroll - this.clientWidthScroll;
  divisibleBy = 1;
  maxScollBy = this.maxScrollLeft / this.divisibleBy;

  connect() {
    useWindowResize(this)
  }

  leftClick() {
    this.scrollBarTarget.scrollLeft <= 0 ? this.scrollBarTarget.scrollLeft = 0 : this.scrollBarTarget.scrollLeft -= this.maxScollBy;
  }
  rightClick() {
    this.scrollBarTarget.scrollLeft >= this.maxScrollLeft - 5 ? this.scrollBarTarget.scrollLeft = this.maxScrollLeft : this.scrollBarTarget.scrollLeft += this.maxScollBy;
  }

  windowResize() {
    this.widthScroll = this.scrollBarTarget.scrollWidth;
    this.clientWidthScroll = this.scrollBarTarget.clientWidth;
    this.maxScrollLeft = this.widthScroll - this.clientWidthScroll;
    // CHANGE THE LAST DIVISBLE BELOW BY NUMBER TO CHANGE THE SCROLL CLICKS NEEDED TO SCROLL
    this.maxScrollLeft < 100 ? this.divisibleBy = 1 : this.divisibleBy = 1;
    this.maxScollBy = this.maxScrollLeft / this.divisibleBy;
  }
}
