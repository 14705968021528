import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["navbar", "searchBox", "navLinks"]

  toggleNavOn() {
    console.log("nav on")

    this.navLinksTarget.style.left = "0";
  }
  toggleNavOff() {
    console.log("nav off")

    this.navLinksTarget.style.left = "-100%";
  }
  toggleSideMenu() {
    console.log("side menu toggle")
    if (this.navLinksTarget.classList.contains("show1")) {
      this.navLinksTarget.classList.remove("show1");
      console.log("side menu toggle remove");

    } else {
      console.log("side menu toggle add");
      this.navLinksTarget.classList.add("show1");
    }
  }
}
