// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

import NavbarController from "./navbar_controller"
application.register("navbar", NavbarController)

import ScrollController from "./scroll_controller"
application.register("scroll", ScrollController)

import MapController from "./map_controller"
application.register("map", MapController)

import ModalsController from "./modals_controller"
application.register("modals", ModalsController)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
